import './style.css';
import MenuTop from '../../components/Menu'
import icone2 from '../../assets/imgs/icone02.png'
import icone3 from '../../assets/imgs/icone03.png'
import icone4 from '../../assets/imgs/icone04.png'

import icone8 from '../../assets/imgs/icone08.png'

import TextField from '@mui/material/TextField';

import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'


import { useState, useEffect } from 'react'
import api from '../../services/api'
import Swal from 'sweetalert2'
import React from 'react';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import YouTubePlaylist from '../../components/YouTube'
import InstaFeed from '../../components/InstaFeed'
import { AiFillLinkedin } from "react-icons/ai"


function handleClick() {
    // URL que você deseja abrir
    const url = 'https://calendly.com/canadasimplificado/consulting-tax-benefits';
    window.open(url, '_blank');

}
function handleClick2() {
    // URL que você deseja abrir
    const url = 'https://calendly.com/canadasimplificado/consulting-financial';
    window.open(url, '_blank');

}
function handleClick3() {
    // URL que você deseja abrir
    const url = 'https://calendly.com/canadasimplificado/consulting-empresa';
    window.open(url, '_blank');
}
function handleClick4() {
    // URL que você deseja abrir
    const url = 'https://calendly.com/canadasimplificado';
    window.open(url, '_blank');
}

const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
};





function HomePage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [email, setEmail] = useState('');
    const [assunto, setAssunto] = useState('');


    const [consulting, setConsulting] = useState('');
    const [bookkeeping, setBookkeeping] = useState('');
    const [depoimentos, setDepoimentos] = useState('');
    const [consultoria, setConsultoria] = useState('');
    const [colaboradores, setColaboradores] = useState('');
    const [certificados, setCertificados] = useState('');
    const [servicos, setServicos] = useState('');
    const [ativos, setAtivos] = useState('');
    const [valor, setValor] = useState('');
    const [open, setOpen] = useState(false);
    const [quemsomos, setQuemSomos] = useState('');
    
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            nome,
            sobrenome,
            email,
            assunto,
        };

        try {
            await api.post('/contacts/emails/create', data);

            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Sucesso!',
                text: "E-mail enviado com sucesso.",
                showConfirmButton: false,
                timer: 2500
            })

            // Limpar os campos após o envio bem-sucedido, se desejar
            setNome('');
            setSobrenome('');
            setEmail('');
            setAssunto('');
        } catch (error) {
            console.error('Erro ao enviar os dados:', error);
        }
    };
    const handleSubmitModal = async (e) => {
        e.preventDefault();

        const data = {
            nome,
            sobrenome,
            email,
        };

        try {
            await api.post('/cliente/create', data);



            handleClose()

            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Sucesso!',
                text: "E-mail enviado com sucesso.",
                showConfirmButton: false,
                timer: 2500
            })

            // Limpar os campos após o envio bem-sucedido, se desejar
            setNome('');
            setSobrenome('');
            setEmail('');
        } catch (error) {
            console.error('Erro ao enviar os dados:', error);
        }
    };
    async function loadConsulting() { // Torna a função assíncrona aqui
        try {
            const response = await api.get('/consulting'); // Espere pela resposta da API
            setConsulting(response.data);

        } catch (error) {
            console.log(error.response.data);
        }
    }

    function LoadBookeeping(params) {
        api.get('/bookkeeping')
            .then(res => {

                setBookkeeping(res.data)
            })
            .catch(e => {
                console.log(e.response.data);
            })


    }
    function LoadDepoimentos(params) {
        api.get('/depoiments')
            .then(res => {

                setDepoimentos(res.data)
            })
            .catch(e => {
                console.log(e.response.data);
            })


    }
    function LoadConsutoria(params) {
        api.get('/consultoria')
            .then(res => {

                setConsultoria(res.data)
            })
            .catch(e => {
                console.log(e.response.data);
            })


    }
    function LoadColaboradores(params) {
        api.get('/colaboradores')
            .then(res => {

                setColaboradores(res.data)
            })
            .catch(e => {
                console.log(e.response.data);
            })


    }

    function LoadCertificados(params) {
        api.get('/certificados')
            .then(res => {
                setCertificados(res.data)
            })
            .catch(e => {
            })

    }


    function LoadServiocos(params) {
        api.get('/servicos')
            .then(res => {

                setServicos(res.data)
            })
            .catch(e => {

            })


    }


    function LoadAtivos(params) {
        api.get('/visibilidade')
            .then(res => {

               
                setAtivos(res.data)
            })
            .catch(e => {
                console.log("Erro", e.response.data);
            })
    }

    function LoadValor(params) {
        api.get('/valor')
            .then(res => {

                const dados = res.data[0];
                setValor(dados.texto);

               
            })
            .catch(e => {
                console.log(e.response.data);
            })


    }


    function LoadQuemSomos(params) {
        api.get('/quem_somos')
            .then(res => {
                console.log("Quem Somos", res.data);
                setQuemSomos(res.data)
            })
            .catch(e => {
                console.log(e.response.data);
            })


    }



    useEffect(() => {
        window.scrollTo(0, 0);
        loadConsulting();
        LoadBookeeping();
        LoadDepoimentos();
        LoadConsutoria();
        LoadColaboradores();
        LoadCertificados();
        LoadServiocos();
        LoadAtivos();
        LoadValor();
        LoadQuemSomos();
    }, []);


    const handleClicks = () => {
        scrollToSection('contato'); // Substitua 'contato' pelo ID da seção que deseja rolar suavemente
    };

    // Extrai as partes do valor para montar o formato desejado
    const partes = valor.split('$');
    const inteiro = partes[1]?.split('.')[0] || '';
    const decimal = partes[1]?.split('.')[1]?.split(' ')[0] || '';
    const sufixo = partes[1]?.split('.')[1]?.split(' ').slice(1).join(' ') || '';




    return (
        <>
            <MenuTop />

            <hr />

            <section className='section-info-slider'>

                <div className='content-info-home'>
                    <h1>
                        SIMPLIFICANDO <br />
                        SUA VIDA NO <br />
                        CANADÁ <br />
                    </h1>

                    <button className='buttom-flc' onClick={handleClicks}>
                        Fale Conosco
                    </button>
                </div>


            </section>


            {ativos.length > 0 && ativos.some(ativo => ativo.nome_secao === "Consulting" && ativo.ativo === 1) && (
                <section className='section-master' id="consultingservices" >

                    <section className='section-info-consulting'>
                        <div className='info-01'>
                            <div>
                                <h1>CONSULTING</h1>
                                {consulting.length > 0 && (
                                    <p>{consulting[0].texto}</p>
                                )}
                            </div>
                            {consulting.length > 0 && (
                                <>
                                    <div>

                                        <img className='imgIcon1' src={consulting[0].url_imagem} alt="" />
                                    </div>
                                </>
                            )}
                        </div>
                    </section>


                    <section className='section-info-value-option-consulting' >



                        <h1>
                            {partes[0]} <span>${inteiro}
                                <span>
                                    {decimal}
                                </span>
                            </span> {sufixo}
                        </h1>




                        <div className='content-info-services'>

                            <div className='option-service' onClick={handleClick}>
                                <img src={icone2} alt="" />
                                <h2 className='name-info-service'>Tax & Benefits</h2>

                                <div>

                                    <p>
                                        Saiba mais sobre os benefícios  br
                                        oferecidos pelo governo,
                                        formulários do CRA
                                        e otimização tributária pessoal. <br /> <br />
                                        Recomendada em especial
                                        para quem chegou
                                        recentemente (até dois anos).
                                    </p>
                                </div>
                            </div>
                            <div className='option-service' onClick={handleClick2}>
                                <img src={icone3} alt="" />
                                <h2 className='name-info-service'>Financial</h2>
                                <div>

                                    <p>
                                        De forma individualizada, tiramos
                                        dúvidas sobre os principais
                                        assuntos de sua vida financeira,
                                        com foco em investimentos. <br /> <br />

                                        Essa reunião é indicada
                                        tanto para quem já está no
                                        Canadá há algum tempo quanto
                                        para quem acabou de chegar.
                                    </p>
                                </div>
                            </div>
                            <div className='option-service' onClick={handleClick3}>
                                <div className='org-info-img-tax'>

                                    <img src={icone4} alt="" />
                                </div>
                                <h2 className='name-info-service'>Abertura de Empresa ou Autônomo</h2>
                                <div>

                                    <p>
                                        Consultoria para quem está
                                        interessado em abrir uma
                                        pequena empresa ou trabalhar
                                        como autônomo
                                        (self-employed) no Canadá.
                                    </p>
                                </div>
                            </div>



                        </div>
                        <div className='content-info-services-mobile'>

                            <div className='option-service-mob' onClick={handleClick}>
                                <div className='org-info-img-tax'>

                                    <img src={icone2} alt="" />
                                </div>

                                <div className='org-text-info-ser'>


                                    <h2 className='name-info-service-mob'>Tax & Benefits</h2>

                                    <div>

                                        <p>
                                            Saiba mais sobre os benefícios
                                            oferecidos pelo governo,
                                            formulários ...
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='option-service-mob' onClick={handleClick2}>
                                <div className='org-info-img-tax'>

                                    <img src={icone3} alt="" />
                                </div>

                                <div className='org-text-info-ser'>

                                    <h2 className='name-info-service-mob'>Financial</h2>
                                    <div>

                                        <p>
                                            De forma individualizada, tiramos
                                            dúvidas sobre os principais
                                            assuntos...
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='option-service-mob' onClick={handleClick3}>
                                <div className='org-info-img-tax'>

                                    <img src={icone4} alt="" />
                                </div>
                                <div className='org-text-info-ser'>

                                    <h2 className='name-info-service-mob'>Abertura de Empresa ou Autônomo</h2>
                                    <div>

                                        <p>
                                            Consultoria para quem está
                                            interessado em abrir uma
                                            pequena ...
                                        </p>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </section>
                </section>
            )}




            {ativos.length > 0 && ativos.some(ativo => ativo.nome_secao === "Serviços" && ativo.ativo === 1) && (
                <section className='section-info-tax-services' id="taxservice">
                    <h2> TAX SERVICES</h2>
                    <h4>
                        De forma simples e rápida, com profissional <br />
                        especializada no assunto!
                    </h4>
                    <h1>CONFIRA NOSSOS VALORES PARA 2024</h1>

                    <div className='content-info-service-value'>



                        {servicos.length > 0 ? servicos.map((value, key) => (
                            <div className='option-service ' key={key}>

                                <img src={value.url_imagem} alt="" />
                                <h3>{value.nome}</h3>
                                <p>a partir de</p>
                                <h1>$ {value.preco}</h1>

                            </div>


                        )) : <></>}




                    </div>
                    <div className='content-info-service-value-mob'>



                        {servicos.length > 0 ? servicos.map((value, key) => (


                            <div className='option-service-mob ' key={key}>

                                <img src={value.url_imagem} alt="" />
                                <div>

                                    <h3>{value.nome}</h3>
                                    <p>a partir de</p>
                                    <h1>$ {value.preco}</h1>
                                </div>

                            </div>


                        )) : <></>}
                    </div>

                    <div className='info-bt-sv'>
                        <p>

                            Todas as opções incluem suporte completo  em caso<br /> de review ou auditoria pelo CRA
                        </p>




                        <Link to="/simplifica-canada-angendamento">
                            <div className='buttom-click-list'>

                                <p>Entre na nossa lista de espera</p>
                            </div>
                        </Link>
                    </div>



                </section>

            )}


{/* 
            {ativos.length > 0 && ativos.some(ativo => ativo.nome_secao === "Bookkeeping" && ativo.ativo === 1) && (
                <section className='section-bookkeeping-recursos' id="bookkeping">

                    <div className='section-master'>


                        <h1>BOOKKEEPING</h1>

                        <div className='org-info-bookkeeping-recursos'>

                            {bookkeeping.length > 0 && (
                                <p>{bookkeeping[0].texto}</p>
                            )}



                            <div className='div-bookeeping'>
                                {bookkeeping.length > 0 && (
                                    <>
                                        <img className='imgIcon1' src={bookkeeping[0].url_imagem} alt="" />
                                    </>
                                )}
                            </div>


                        </div>

                        <div className='buttom-click-contato' onClick={handleClicks}>
                            <p> Fale Conosco</p>
                        </div>
                    </div>
                </section>

            )} */}

{/* 
            <section className='section-recuros' id='recursos'>

                <div className='section-master'>
                    <h1 className='h1-rec'>RECURSOS</h1>
                    <div className='info-org-recursos' >

                        {ativos.length > 0 && ativos.some(ativo => ativo.nome_secao === "Blog" && ativo.ativo === 1) && (
                            <div className='org-recursos'>
                                <Link to="/simplifica-canada-blog">

                                    <img src="https://img.freepik.com/vetores-gratis/blogar-divertido-criacao-de-conteudo-streaming-online-videoblog-jovem-fazendo-selfie-para-rede-social-compartilhando-feedback-estrategia-de-autopromocao-ilustracao-vetorial-de-metafora-de-conceito_335657-855.jpg?w=2000" alt="" />

                                    <h4>Blog</h4>
                                    <p>
                                        Nosso blog é o destino definitivo para aqueles que buscam desvendar
                                        os mistérios dos serviços fiscais. Assim como a natureza nos presenteia com sua beleza
                                        diversificada, nosso compromisso é simplificar os complexos aspectos tributários,
                                        oferecendo orientação especializada para garantir sua tranquilidade financeira.
                                    </p>
                                </Link>
                            </div>

                        )}


                        <div className='org-recursos' onClick={handleOpen}>
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8LV73Bm7Y46MMPk8PZO5nYV1l1etZf0kauw&usqp=CAU" alt="" />
                            <div>
                                <h4>Mais Recursos</h4>
                                <p>
                                    Explore nossa seção 'Mais Recursos' para descobrir uma ampla variedade de
                                    ferramentas, informações e conteúdo adicional que aprimoram sua experiência no nosso site. Aqui,
                                    você encontrará recursos adicionais para aprofundar seu conhecimento, resolver dúvidas e aproveitar ao máximo nossa plataforma.
                                </p>
                            </div>
                        </div>

                        {ativos.length > 0 && ativos.some(ativo => ativo.nome_secao === "Ebooks" && ativo.ativo === 1) && (
                            <div className='org-recursos' >

                                <Link to="/simplifica-canada-ebooks">

                                    <img src="https://img.freepik.com/vetores-gratis/ebook_24908-54028.jpg?w=2000" alt="" />
                                    <h4>E-books</h4>
                                    <p>
                                        Nossa plataforma é o seu portal para explorar e absorver conhecimento
                                        por meio de e-books envolventes e transformadores. Assim como a natureza revela
                                        sua beleza diversificada, nossos e-books são uma fonte de insights
                                        enriquecedores, projetados para simplificar temas complexos e enriquecer sua jornada intelectual.
                                    </p>
                                </Link>
                            </div>
                        )}
                    </div>
                    <div className='info-org-recursos-mob' >
                        <Link to="/simplifica-canada-blog">
                            <div className='org-recursos'>


                                <img src="https://img.freepik.com/vetores-gratis/blogar-divertido-criacao-de-conteudo-streaming-online-videoblog-jovem-fazendo-selfie-para-rede-social-compartilhando-feedback-estrategia-de-autopromocao-ilustracao-vetorial-de-metafora-de-conceito_335657-855.jpg?w=2000" alt="" />

                                <div>

                                    <h4>Blog</h4>
                                    <p>
                                        Nosso blog é o destino definitivo para aqueles que buscam...
                                    </p>
                                </div>

                            </div>
                        </Link>

                        <div className='org-recursos' onClick={handleOpen}>


                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8LV73Bm7Y46MMPk8PZO5nYV1l1etZf0kauw&usqp=CAU" alt="" />


                            <div>

                                <h4>Mais Recursos</h4>
                                <p>
                                    Explore nossa seção 'Mais Recursos' para descobrir uma ampla variedade...
                                </p>
                            </div>

                        </div>

                        <Link to="/simplifica-canada-ebooks">
                            <div className='org-recursos' >


                                <img src="https://img.freepik.com/vetores-gratis/ebook_24908-54028.jpg?w=2000" alt="" />


                                <div>

                                    <h4>E-books</h4>
                                    <p>
                                        Nossa plataforma é o seu portal para explorar e absorver...
                                    </p>
                                </div>

                            </div>
                        </Link>

                    </div>
                </div>

            </section> */}



            {ativos.length > 0 && ativos.some(ativo => ativo.nome_secao === "Depoimentos" && ativo.ativo === 1) && (
                <section className='section-depoimentos' id="depoimentos">

                    <h1>DEPOIMENTOS</h1>

                    <div className='org-infos-depoismentos'>




                        {depoimentos.length > 0 ? depoimentos.map((value, index) => (

                            <div key={index} className='info-depoimentos'>
                                <h2>{value.nome}</h2>
                                <p>
                                    {value.texto}

                                </p>

                            </div>


                        )) : <></>}



                    </div>

                </section>

            )}

            <section className='section-master'>

                <section className='section-nosso-time'>

                    <div>
                        <h1>CONHEÇA NOSSO TIME!</h1>
                        <p>
                            Baseados na região metropolitana de Calgary (Alberta), tendo as montanhas
                            rochosas como nosso quintal, nossa missão é ajudar imigrantes brasileiros
                            a construírem histórias de sucesso no Canadá.
                        </p>
                    </div>

                    <div className='aling_img'>
                        <img src={icone8} alt="" />
                    </div>
                </section>

                <section className='section-nosso-timer-02'>

                    <div className='info-time-group'>
                        {colaboradores.length > 0 ? colaboradores.map((value, index) => {
                            let linkedinUrl;

                            if (value.nome === "Jamine Souza") {
                                linkedinUrl = "https://www.linkedin.com/in/jaminesouza/";
                            } else if (value.nome === "Daniel Souza") {
                                linkedinUrl = "https://www.linkedin.com/in/danielpsms/";
                            } else {
                                linkedinUrl = "#"; // URL padrão caso nenhum dos nomes corresponda
                            }

                            return (
                                <div className='org-info-time' key={index}>
                                    <div className='i-img'>
                                        <img src={value.url_imagem} alt="" />
                                    </div>
                                    <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
                                        <AiFillLinkedin className='icon-link' />
                                    </a>
                                    <h3>{value.nome}</h3>
                                    <p>{value.funcao}</p>
                                </div>
                            );
                        }) : <></>}
                    </div>


                </section>

            </section>


            {ativos.length > 0 && ativos.some(ativo => ativo.nome_secao === "Consultoria" && ativo.ativo === 1) && (
                <section className='section-consultoria' >

                    <h1>
                        Consultoria TRIBUTÁRIA, CONTÁBIL e <br />
                        FINANCEIRA para imigrantes no Canadá
                    </h1>

                    {consultoria.length > 0 ? consultoria.map((value, index) => (
                        <div key={index} className='org-info-consultoria'>
                            <div>
                                <p>
                                    {value.texto1}
                                </p>
                            </div>
                            <div>
                                <p>
                                    {value.texto2}
                                </p>
                            </div>
                            <div>
                                <p>
                                    {value.texto3}
                                </p>
                            </div>
                            <div>
                                <p>
                                    {value.texto4}
                                </p>
                            </div>


                        </div>

                    )) :
                        <></>
                    }




                    <div className='button-agendar' onClick={handleClick4}>
                        <p>Agende a sua consultoria agora</p>
                    </div>


                </section>
            )}

            {ativos.length > 0 && ativos.some(ativo => ativo.nome_secao === "Quem Somos" && ativo.ativo === 1) && (
                <section className='section-master' id='quemsomos'>
                    <section className='section-quemsomos'>
                        <h1>QUEM SOMOS?</h1>


                        {quemsomos.length > 0 ? quemsomos.map((value, index) => (
                        
                        <p  key={index}>
                            {value.texto}
                        </p>

                    )) :
                        <></>
                    }

                    </section>
                </section>
            )}
            <section className='section-redes-sociais'>

                <h1>Redes Sociais</h1>
                {ativos.length > 0 && ativos.some(ativo => ativo.nome_secao === "Youtube" && ativo.ativo === 1) && (
                    <div>


                        <YouTubePlaylist />
                    </div>
                )}

                {ativos.length > 0 && ativos.some(ativo => ativo.nome_secao === "Instagram" && ativo.ativo === 1) && (
                    <div>
                        <InstaFeed />
                    </div>

                )}



            </section>

            <section className='section-master'>
                <section className='section-certificados'>
                    <h1>CERTIFIED</h1>

                </section>

                <section className='seciton-contato'>


                    {certificados.length ? certificados.map((value, key) => (

                        <img src={value.url_imagem} alt="" key={key} />

                    )) : <></>}




                </section>

                <hr className='hr-footer' />


                <section className='section-contato-form' id="contato">
                    <div>
                        <h1>CONTATO</h1>
                        <div>
                            <p>contato@canadasimplificado.ca</p>
                        
                        </div>

                        <div className='button-contato' onClick={handleClick4}>
                            <p>Agende Agora a sua Consultoria</p>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='input-2'>
                            <TextField
                                id="outlined-basic"
                                label="Nome"
                                variant="outlined"
                                type="text"
                                value={nome} onChange={(e) => setNome(e.target.value)} placeholder='Nome' required
                            />
                            <TextField
                                id="outlined-basic"
                                label="Sobrenome"
                                variant="outlined"
                                type="text"
                                value={sobrenome} onChange={(e) => setSobrenome(e.target.value)} placeholder='Sobrenome' required

                            />
                        </div>
                        <div className='input-1'>
                            <TextField
                                id="outlined-basic"
                                label="E-mail"
                                variant="outlined"
                                type="email"
                                value={email} onChange={(e) => setEmail(e.target.value)} placeholder='E-mail' required
                            />
                        </div>
                        <div className='input-1'>
                            <TextField id="outlined-basic" label="Assunto..." variant="outlined"
                                required value={assunto} onChange={(e) => setAssunto(e.target.value)} placeholder='Assunto'
                                multiline
                                rows={4}
                            />
                        </div>

                        <button type='submit'>
                            Enviar
                        </button>
                    </form>

                </section>

            </section>


            <Footer />


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >

                <form onSubmit={handleSubmitModal}>

                    <div className='container-modal-page'>
                        <h2>Inscreva-se para receber mais conteúdos!</h2>

                        <div className='two-input'>


                            <TextField
                                label="nome"
                                variant="outlined"
                                style={{ margin: '10px 0' }}
                                onChange={(e) => setNome(e.target.value)}

                            />
                            <TextField
                                label="sobrenome"
                                variant="outlined"
                                style={{ margin: '10px 0' }}
                                onChange={(e) => setSobrenome(e.target.value)}

                            />
                        </div>
                        <div className='one-input'>
                            <TextField
                                label="email"
                                variant="outlined"
                                style={{ margin: '10px 0' }}
                                onChange={(e) => setEmail(e.target.value)}

                            />
                        </div >

                        <div className='button-modal'>

                            <Button variant="contained" className="btn" type='submit'>
                                Enviar
                            </Button>
                        </div>
                    </div>
                </form>
            </Modal>


        </>




    );
}

export default HomePage;
