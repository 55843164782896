
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Home from './pages/home/index'
import Blog from './pages/blog/index'
import DetalhesBlog from './pages/blog/detalhesBlog'
import Ebooks from './pages/ebooks/index'
import Agendamento from './pages/Agendamento'


export default function RoutesApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/simplifica-canada-blog"  element={<Blog />} />
                <Route path="/simplifica-canada-blog-detalhes/:id" element={<DetalhesBlog/>} />
                <Route path="/simplifica-canada-ebooks"  element={<Ebooks />} />
                <Route path="/simplifica-canada-angendamento"  element={<Agendamento />} />

            </Routes>
        </BrowserRouter>
    )
}   