import React, { useState, useEffect } from 'react'
import './styles.css'
import Header from '../../components/Menu'
import Footer from '../../components/Footer'

import api from '../../services/api'
import moment from 'moment';
import 'moment/locale/pt-br';  // Importando o locale pt-br
import { Link } from 'react-router-dom'
moment.locale('pt-br');  // Configurando o locale para pt-br




export default function Blog() {

    const [consulting, setConsulting] = useState('');


    async function loadConsulting() { // Torna a função assíncrona aqui
        try {
            const response = await api.get('/blog'); // Espere pela resposta da API

            console.log(response.data);
            setConsulting(response.data);
        } catch (error) {
            console.log(error.response.data);
        }
    }

    useEffect(() => {
        loadConsulting();
    }, []);


    return (
        <>
            <Header />
            <section className='container-section-blog'>
                <h1 className='text-b'>Últimos conteudos adicionados</h1>

                <div className='container-blog'>


                    {consulting.length > 0 ? (
                        consulting.map((value, index) => (
                            <div className="content-ebook" key={index}>
                                <div className="button-down" >
                                    <Link to={`/simplifica-canada-blog-detalhes/${value.id}`} >
                                        <h3>Ver mais</h3>
                                    </Link>
                                </div>
                                <div className="info-data-ebbok" >
                                    <img src={value.url_imagem} alt="" />
                                    <div className='blog-info'>
                                        <h2>{value.titulo}</h2>
                                        <p>Descrição: {value.texto}</p>
                                    </div>
                                    <Link to={`/simplifica-canada-blog-detalhes/${value.id}`} >
                                        <div className="button-down-mob">
                                            <h3>Ver mais</h3>
                                        </div>
                                    </Link>
                                </div>
                                <p>Criado {moment(value.data_criacao).format('L')}</p>


                            </div>
                        ))
                    ) : (
                        <></>
                    )}

                </div>



            </section>
            <Footer />
        </>
    )
}
