import React, { useState, useEffect } from "react";
import './styles.css'

import Header from '../../components/Menu'
import Footer from '../../components/Footer'
import api from '../../services/api'
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';



export default function PageEbbok() {


    const [consulting, setConsulting] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [linkURL, setLinkURL] = useState('');

    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [email, setEmail] = useState('');
    const [assunto, setAssunto] = useState('canada');



    const handleChangeAssunto = (e) => {

        setAssunto(e.target.value);


    }

    function openModal(urlLink) {
        setModalIsOpen(true);
        document.body.classList.add('modal-open'); // Adicione uma classe ao <body>

        setLinkURL(urlLink)

    }

    // Função para fechar o modal e habilitar o scroll novamente
    const closeModal = () => {
        setModalIsOpen(false);
        document.body.classList.remove('modal-open'); // Remova a classe do <body>
    };

    // Use o useEffect para limpar qualquer alteração no scroll ao desmontar o componente
    useEffect(() => {
        return () => {
            document.body.classList.remove('modal-open'); // Certifique-se de limpar a classe ao desmontar o componente
        };
    }, []);

    async function loadConsulting() { // Torna a função assíncrona aqui
        try {
            const response = await api.get('/ebooks'); // Espere pela resposta da API

            console.log(response.data);
            setConsulting(response.data);
        } catch (error) {
            console.log(error.response.data);
        }
    }

    useEffect(() => {
        loadConsulting();
    }, []);

    function DowPDF() {
        if (linkURL) {
            if (isMobileDevice()) {
                // Se for um dispositivo móvel, tente forçar o download
                const link = document.createElement('a');
                link.href = linkURL;
                link.target = '_blank'; // Garantindo que tente abrir em uma nova janela/tab
                link.download = 'filename.pdf';  // Esta propriedade força o download; substitua 'filename.pdf' pelo nome desejado para o arquivo
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                // Se não for móvel, abra em uma nova janela/tab
                window.open(linkURL, '_blank');
            }
        }
    }


    function isMobileDevice() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Testes para dispositivos específicos
        if (/windows phone/i.test(userAgent)) {

            return true;
        }
        if (/android/i.test(userAgent)) {

            return true;
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {

            return true;
        }
        if (/IEMobile/i.test(userAgent)) {

            return true;
        }

        // Se nenhum dispositivo for detectado
        return false;
    }

    async function handleSubmit(e) {
        e.preventDefault();


        const data = {
            nome,
            sobrenome,
            email,
            assunto,
        };

        data.sobrenome = 'none'

        try {
            await api.post('/contacts/create', data);  // Aguardar a resposta da API
            console.log("Contente", data);
            // Limpar os campos após o envio bem-sucedido
            // Tente abrir o PDF aqui, logo após enviar os dados
            DowPDF()

            setNome('');
            setSobrenome('');
            setEmail('');
            setAssunto('');

            closeModal();
        } catch (error) {
            console.error('Erro ao enviar os dados:', error);
        }
    }

    const currencies = [
        {
            value: 'brasil',
            label: 'Brasil',
        },
        {
            value: 'canada',
            label: 'Canadá',
        },
        {
            value: 'outro',
            label: 'Outro',
        }
    ];


    return (
        <>
            <Header />

            <section className="section-ebooks">
                <div className="contente-info-ebook">

                    <h1 className="text-dow">E-books para Download</h1>

                    {consulting.length > 0 ? (
                        consulting.map((value, index) => (
                            <div className="content-ebook" key={index}>
                                <div className="button-down" onClick={() => openModal(value.url_pdf)}>
                                    <h3>Baixar Ebook</h3>
                                </div>
                                <div className="info-data-ebbok" >
                                    <img src={value.url_imagem} alt="" />
                                    <div>
                                        <h2>{value.titulo}</h2>
                                        <p>Descrição: {value.descricao}</p>
                                    </div>
                                    <div className="button-down-mob" onClick={() => openModal(value.url_pdf)}>
                                        <h3>Baixar Ebook</h3>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </div>

                {/* Modal */}
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Modal"
                    className="overlay content"

                >
                    {/* Conteúdo do modal aqui */}


                    <form onSubmit={handleSubmit} className="custom-modal-content">

                        <h2>Para receber o material, <br />
                            preencha os dados abaixo</h2>


                        <TextField id="outlined-basic" label="Nome Completo" variant="outlined"
                            name="name"
                            className="input-tam"
                            placeholder="Nome Completo"
                            value={nome} onChange={(e) => setNome(e.target.value)}
                            required

                        />
                        <br />

                        <TextField id="outlined-basic" label="E-maiil" variant="outlined"
                            name="email"
                            placeholder="Digite seu e-mail..."
                            className="input-tam"
                            value={email} onChange={(e) => setEmail(e.target.value)}
                            required

                        />


                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Província"
                            className="input-tam selectinput"
                            name="country" 
                            required value={assunto} onChange={handleChangeAssunto}
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>


                        <br />

                        {nome !== "" && email !== "" && assunto !== "" ?
                            <button type="submit" className="downloadPDF">
                                Quero receber meu material
                            </button>
                            :
                            <button type="submit" className="downloadPDF" disabled>
                                Quero receber meu material
                            </button>
                        }

                    </form>



                </Modal>

            </section>

            <Footer />
        </>
    )

}