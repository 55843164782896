import './style.css';
import logo from '../../assets/imgs/logo.png'
import React, { useState, useEffect } from 'react';

import { HiMenu } from 'react-icons/hi'
import { IoMdClose } from 'react-icons/io'

function HomePage() {
    const [isMenuFixed, setIsMenuFixed] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [mobileMenuIcon, setMobileMenuIcon] = useState('menu');

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsMenuFixed(true);
            } else {
                setIsMenuFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const scrollToSection = (sectionId) => {
        if (
            sectionId === 'quemsomos' ||
            sectionId === 'consultingservices' ||
            sectionId === 'depoimentos' ||
            sectionId === 'contato'
        ) {
            // Verifique se estamos na página principal
            if (window.location.pathname !== '/') {
                // Redirecione para a página principal
                window.location.href = '/';
            }

            const section = document.getElementById(sectionId);
            if (section) {
                // Defina a margem superior desejada (por exemplo, 20 pixels)
                const margin = 135;
                // Calcule o ponto de destino com margem
                const offsetTop = section.offsetTop - margin;

                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
            }
        }
        closeMobileMenu();
    };


    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
        setMobileMenuIcon(isMobileMenuOpen ? 'menu' : 'close');
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
        setMobileMenuIcon('menu');
    };

    return (

        <header className={`header-menu ${isMenuFixed ? 'fixed' : ''}`}>

            <div className='content-menu-top'>
                <div className='content-img'>
                    <a href="/" >
                        <img src={logo} alt="Simplifica Canada" />
                    </a>
                </div>
                <div className='option-menu'>

                    <span onClick={() => scrollToSection('quemsomos')}>
                        <p>Quem Somos</p>
                    </span>
                    <span href="" onClick={() => scrollToSection('consultingservices')}>
                        <p>Consulting Services</p>
                    </span>
                    {/* <span href="" onClick={() => scrollToSection('depoimentos')}>
                        <p>Depoimentos</p>
                    </span> */}
                    <span href="" onClick={() => scrollToSection('contato')}>
                        <p>Contato</p>
                    </span>

                </div>


                <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>

                    <div className='position-optins-menu'>



                        <span onClick={() => scrollToSection('quemsomos')}>
                            <p>Quem Somos</p>
                        </span>
                        <span href="" onClick={() => scrollToSection('consultingservices')}>
                            <p>Consulting Services</p>
                        </span>
                        {/* <span href="" onClick={() => scrollToSection('depoimentos')}>
                            <p>Depoimentos</p>
                        </span> */}
                        <span href="" onClick={() => scrollToSection('contato')}>
                            <p>Contato</p>
                        </span>
                    </div>
                </div>
                <div className='mobile-menu-toggle' onClick={toggleMobileMenu}>
                    {mobileMenuIcon === 'menu' ? (
                        <HiMenu size={40} />
                    ) : (
                        <IoMdClose size={40} />
                    )}
                </div>
            </div>

        </header>
    );
}

export default HomePage;
