import React, { useState, useEffect } from 'react';
import Header from '../../components/Menu';
import Footer from '../../components/Footer';
import './styles.css';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import api from '../../services/api';
import Swal from 'sweetalert2';

export default function Index() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [formData, setFormData] = useState({
        nome: '',
        email: '',
        provincia: '',
        servico: '',
        mais_informacao: '',
        telefone: '',
    });

    const provinciasCanada = [
        {
            value: 'Alberta',
            label: 'Alberta',
        },
        {
            value: 'British Columbia',
            label: 'British Columbia',
        },
        {
            value: 'Manitoba',
            label: 'Manitoba',
        },
        {
            value: 'New Brunswick',
            label: 'New Brunswick',
        },
        {
            value: 'Newfoundland and Labrador',
            label: 'Newfoundland and Labrador',
        },
        {
            value: 'Northwest Territories',
            label: 'Northwest Territories',
        },
        {
            value: 'Nova Scotia',
            label: 'Nova Scotia',
        },
        {
            value: 'Nunavut',
            label: 'Nunavut',
        },
        {
            value: 'Ontario',
            label: 'Ontario',
        },
        {
            value: 'Prince Edward Island',
            label: 'Prince Edward Island',
        },
        {
            value: 'Quebec',
            label: 'Quebec',
        },
        {
            value: 'Saskatchewan',
            label: 'Saskatchewan',
        },
        {
            value: 'Yukon',
            label: 'Yukon',
        },
    ];


    const servicos = [
        {
            value: 'Tax Individual',
            label: 'Tax Individual',
        },
        {
            value: 'Tax Casal',
            label: 'Tax Casal',
        },
        {
            value: 'Self-employed',
            label: 'Self-employed',
        }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            await api.post('/agendamentos/create', formData);

            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Sucesso!',
                text: 'Dados enviados com sucesso.',
                showConfirmButton: false,
                timer: 2500,
            });

            // Limpar os campos após o envio bem-sucedido, se desejar
            setFormData({
                nome: '',
                email: '',
                provincia: '',
                servico: '',
                mais_informacao: '',
                telefone: '',
            });
        } catch (error) {
            console.error('Erro ao enviar os dados:', error);
        }
    };

    return (
        <>
            <Header />
            <h2>Formulário de Agendamento</h2>

            <section className='section-agendamento-cliente'>
                <form onSubmit={handleSubmit}>
                    <div className='input-one'>
                        <TextField
                            id='outlined-basic'
                            label='Nome Completo'
                            variant='outlined'
                            name='nome'
                            value={formData.nome}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='input-two'>
                        <TextField
                            id='outlined-basic'
                            label='E-mail'
                            variant='outlined'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                        />
                        <TextField
                            id='outlined-basic'
                            label='Telefone'
                            variant='outlined'
                            name='telefone'
                            value={formData.telefone}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='input-two'>
                        <TextField
                            id='outlined-select-currency'
                            select
                            label='Província'
                            helperText='Por favor selecione a província'
                            name='provincia'
                            value={formData.provincia}
                            onChange={handleChange}
                        >
                            {provinciasCanada.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            id='outlined-select-currency'
                            select
                            label='Serviço'
                            helperText='Por favor selecione o serviço'
                            name='servico'
                            value={formData.servico}
                            onChange={handleChange}
                        >
                            {servicos.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className='input-one'>
                        <TextField
                            id='outlined-basic'
                            label='Mais informações ou detalhes relevantes....'
                            multiline
                            rows={5}
                            variant='outlined'
                            name='mais_informacao'
                            value={formData.informacoes}
                            onChange={handleChange}
                            style={{ width: '100% !important', maxWidth: '100% !important' }}
                        />
                    </div>

    
                    <div className='button-option'>
                        <Button variant='contained' className='button' type='submit'>
                            Enviar
                        </Button>
                    </div>
                </form>
            </section>
            <Footer />
        </>
    );
}
