import React, { useState, useEffect } from 'react';
import './styles.css';
import axios from 'axios';
import { AiFillInstagram } from 'react-icons/ai'; // Use o ícone correspondente ao Instagram
import { BiSolidRightArrow } from 'react-icons/bi';


function InstaFeed() {
    const [posts, setPosts] = useState([]);
    const [videoToPlay, setVideoToPlay] = useState(null);

    const [numPostsToShow, setNumPostsToShow] = useState(4); // default para web
    const INSTAGRAM_TOKEN = 'IGQWRNTkhsUkJ5MzkzaVh3SWV4b2E1OF9YQk5UdENoOEFWZAndLdVBBYVpSOFZATLVlpUFBYa0dmM1FweG42TmFJUkZAadTNHWjhyN1dSY0ZAKZAVY1OTRmakdYRFlrcXlfWkRPRGd5eFVwUjJsSlZAaS2ZAER21DV19SVEUZD';

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`https://graph.instagram.com/me/media?fields=id,caption,media_url,thumbnail_url,media_type&access_token=${INSTAGRAM_TOKEN}`);
                setPosts(response.data.data);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        };

        fetchPosts();
    }, []);

    useEffect(() => {
        const updateNumPostsToShow = () => {
            if (window.innerWidth < 768) {
                setNumPostsToShow(2);
            } else {
                setNumPostsToShow(4);
            }
        };

        updateNumPostsToShow();
        window.addEventListener('resize', updateNumPostsToShow);
        return () => {
            window.removeEventListener('resize', updateNumPostsToShow);
        };
    }, []);

    return (
        <div>
            <div className='container-info-name'>


                <div className='org-name-icon'>
                    <AiFillInstagram size={40} />
                    <span>
                        <h2>Instagram Posts</h2>

                    </span>
                </div>

                <a href="https://www.instagram.com/canadasimplificado/" target="_blank" rel="noopener noreferrer">
                    <p>Ver Mais</p>
                    <BiSolidRightArrow />
                </a>


            </div>
            <div className="post-instagram-container">
                {posts.slice(0, numPostsToShow).map(post => (
                    post.media_type === "IMAGE" ? (
                        <img
                            key={post.id}
                            src={post.media_url}
                            alt={post.caption}
                            className='content-image'
                        />
                    ) : (
                        videoToPlay === post.id ? (
                            <video
                                key={post.id}
                                src={post.media_url}
                                className='content-video'
                                controls
                                autoPlay
                            ></video>
                        ) : (
                            <div onClick={() => setVideoToPlay(post.id)}>
                                <img
                                    key={post.id}
                                    src={post.thumbnail_url}
                                    alt={post.caption}
                                    className='content-image'
                                />
                            </div>
                        )
                    )
                ))}
            </div>

            <div className='mobile-button-rs'>
                <a href="https://www.instagram.com/canadasimplificado/" target="_blank" rel="noopener noreferrer">
                    <p>Ver Mais</p>
                    <BiSolidRightArrow />
                </a>
            </div>

        </div>
    );
}

export default InstaFeed;
