import React, { useEffect } from 'react'
import './styles.css'
import Header from '../../../components/Menu'
import Footer from '../../../components/Footer'
import { useParams } from 'react-router-dom';

import { FaUserAlt } from 'react-icons/fa'

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';



import api from '../../../services/api'
import moment from 'moment';
import Filter from 'bad-words';
import 'moment/locale/pt-br';  // Importando o locale pt-br
moment.locale('pt-br');  // Configurando o locale para pt-br

let Custom = ["aidético",
    "aidética",
    "aleijado",
    "aleijada",
    "analfabeto",
    "analfabeta",
    "anus",
    "anão",
    "anã",
    "arrombado",
    "apenado",
    "apenada",
    "baba-ovo",
    "babaca",
    "babaovo",
    "bacura",
    "bagos",
    "baianada",
    "baitola",
    "barbeiro",
    "barraco",
    "beata",
    "bebum",
    "besta",
    "bicha",
    "bisca",
    "bixa",
    "boazuda",
    "boceta",
    "boco",
    "boiola",
    "bokete",
    "bolagato",
    "bolcat",
    "boquete",
    "bosseta",
    "bosta",
    "bostana",
    "boçal",
    "branquelo",
    "brecha",
    "brexa",
    "brioco",
    "bronha",
    "buca",
    "buceta",
    "bugre",
    "bunda",
    "bunduda",
    "burra",
    "burro",
    "busseta",
    "bárbaro",
    "bêbado",
    "bêbedo",
    "caceta",
    "cacete",
    "cachorra",
    "cachorro",
    "cadela",
    "caga",
    "cagado",
    "cagao",
    "cagão",
    "cagona",
    "caipira",
    "canalha",
    "canceroso",
    "caralho",
    "casseta",
    "cassete",
    "ceguinho",
    "checheca",
    "chereca",
    "chibumba",
    "chibumbo",
    "chifruda",
    "chifrudo",
    "chochota",
    "chota",
    "chupada",
    "chupado",
    "ciganos",
    "clitoris",
    "clitóris",
    "cocaina",
    "cocaína",
    "coco",
    "cocô",
    "comunista",
    "corna",
    "cornagem",
    "cornisse",
    "corno",
    "cornuda",
    "cornudo",
    "cornão",
    "corrupta",
    "corrupto",
    "coxo",
    "cretina",
    "cretino",
    "criolo",
    "crioulo",
    "cruz-credo",
    "cu",
    "cú",
    "culhao",
    "culhão",
    "curalho",
    "cuzao",
    "cuzão",
    "cuzuda",
    "cuzudo",
    "debil",
    "débil",
    "debiloide",
    "debilóide",
    "deficiente",
    "defunto",
    "demonio",
    "demônio",
    "denegrir",
    "denigrir",
    "detento",
    "difunto",
    "doida",
    "doido",
    "egua",
    "égua",
    "elemento",
    "encostado",
    "esclerosado",
    "escrota",
    "escroto",
    "esporrada",
    "esporrado",
    "esporro",
    "estupida",
    "estúpida",
    "estupidez",
    "estupido",
    "estúpido",
    "facista",
    "fanatico",
    "fanático",
    "fascista",
    "fedida",
    "fedido",
    "fedor",
    "fedorenta",
    "feia",
    "feio",
    "feiosa",
    "feioso",
    "feioza",
    "feiozo",
    "felacao",
    "felação",
    "fenda",
    "foda",
    "fodao",
    "fodão",
    "fode",
    "fodi",
    "fodida",
    "fodido",
    "fornica",
    "fornição",
    "fudendo",
    "fudeção",
    "fudida",
    "fudido",
    "furada",
    "furado",
    "furnica",
    "furnicar",
    "furo",
    "furona",
    "furão",
    "gai",
    "gaiata",
    "gaiato",
    "gay",
    "gilete",
    "goianada",
    "gonorrea",
    "gonorreia",
    "gonorréia",
    "gosmenta",
    "gosmento",
    "grelinho",
    "grelo",
    "gringo",
    "homo-sexual",
    "homosexual",
    "homosexualismo",
    "homossexual",
    "homossexualismo",
    "idiota",
    "idiotice",
    "imbecil",
    "inculto",
    "iscrota",
    "iscroto",
    "japa",
    "judiar",
    "ladra",
    "ladrao",
    "ladroeira",
    "ladrona",
    "ladrão",
    "lalau",
    "lazarento",
    "leprosa",
    "leproso",
    "lesbica",
    "lésbica",
    "louco",
    "macaca",
    "macaco",
    "machona",
    "macumbeiro",
    "malandro",
    "maluco",
    "maneta",
    "marginal",
    "masturba",
    "meleca",
    "meliante",
    "merda",
    "mija",
    "mijada",
    "mijado",
    "mijo",
    "minorias",
    "mocrea",
    "mocreia",
    "mocréia",
    "moleca",
    "moleque",
    "mondronga",
    "mondrongo",
    "mongol",
    "mongoloide",
    "mongolóide",
    "mulata",
    "mulato",
    "naba",
    "nadega",
    "nádega",
    "nazista",
    "negro",
    "nhaca",
    "nojeira",
    "nojenta",
    "nojento",
    "nojo",
    "olhota",
    "otaria",
    "otario",
    "otária",
    "otário",
    "paca",
    "palhaco",
    "palhaço",
    "paspalha",
    "paspalhao",
    "paspalho",
    "pau",
    "peia",
    "peido",
    "pemba",
    "pentelha",
    "pentelho",
    "perereca",
    "perneta",
    "peru",
    "peão",
    "pica",
    "picao",
    "picão",
    "pilantra",
    "pinel",
    "pinto",
    "pintudo",
    "pintão",
    "piranha",
    "piroca",
    "piroco",
    "piru",
    "pivete",
    "porra",
    "prega",
    "preso",
    "prequito",
    "priquito",
    "prostibulo",
    "prostituta",
    "prostituto",
    "punheta",
    "punhetao",
    "punhetão",
    "pus",
    "pustula",
    "puta",
    "puto",
    ",puxa-saco",
    "puxasaco",
    "penis",
    "pênis",
    "rabao",
    "rabão",
    "rabo",
    "rabuda",
    "rabudao",
    "rabudão",
    "rabudo",
    "rabudona",
    "racha",
    "rachada",
    "rachadao",
    "rachadinha",
    "rachadinho",
    "rachado",
    "ramela",
    "remela",
    "retardada",
    "retardado",
    "ridícula",
    "roceiro",
    "rola",
    "rolinha",
    "rosca",
    "sacana",
    "safada",
    "safado",
    "sapatao",
    "sapatão",
    "sifilis",
    "sífilis",
    "siririca",
    "tarada",
    "tarado",
    "testuda",
    "tesuda",
    "tesudo",
    "tezao",
    "tezuda",
    "tezudo",
    "traveco",
    "trocha",
    "trolha",
    "troucha",
    "trouxa",
    "troxa",
    "tuberculoso",
    "tupiniquim",
    "turco",
    "vaca",
    "vadia",
    "vagal",
    "vagabunda",
    "vagabundo",
    "vagina",
    "veada",
    "veadao",
    "veado",
    "viada",
    "viadagem",
    "viadao",
    "viadão",
    "viado",
    "viadão",
    "víado",
    "xana",
    "xaninha",
    "xavasca",
    "xerereca",
    "xexeca",
    "xibiu",
    "xibumba",
    "xiíta",
    "xochota",
    "xota",
    "xoxota",
]



const customWords = Custom;
const filter = new Filter({ placeHolder: '*', list: customWords });



export default function DetalhesBlog() {

    const { id } = useParams();
    const [gestor, setGestor] = React.useState('')
    const [comentarios, setComentarios] = React.useState([]);
    const [processedText, setProcessedText] = React.useState('')
    const [displayCount, setDisplayCount] = React.useState(5);



    const [formData, setFormData] = React.useState({
        texto: '',
        id: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Verifica se o texto contém palavras proibidas
        if (filter.isProfane(formData.texto)) {
            alert('Comentário contém palavras ofensivas.');
            return;  // Pára o processo aqui
        }

        try {
            formData.id = id;

            console.log(formData);
            await api.post('/comentarios/create', formData)
                .then(res => {
                    console.log("Sucesso");
                })
                .catch(err => {
                    console.log(err.response.data);
                });

            // Limpar os campos após o envio bem-sucedido
            setFormData({
                texto: '',
            });
            LoadDataComentartios();
        } catch (error) {
            console.error('Erro ao enviar os dados:', error);
        }
    };



    function LoadData(params) {
        api.get(`blog/${id}`)
            .then(res => {


                setGestor({
                    ...res.data
                })

                setProcessedText(processTextForParagraphs(res.data.texto));

            })
            .catch(e => {

            })
    }

    function LoadDataComentartios(params) {
        api.get(`comentarios/${id}`)
            .then(res => {


                console.log(res.data);
                setComentarios(res.data);

            })
            .catch(e => {

            })
    }

    useEffect(() => {
        LoadData()
        LoadDataComentartios()
    }, [])



    const processTextForParagraphs = (text, threshold = 100) => {
        const sentences = text.split('.').map(sentence => sentence.trim()).filter(Boolean);

        let processedText = '';
        for (let i = 0; i < sentences.length; i++) {
            if (i < sentences.length - 1 &&
                sentences[i].length > threshold &&
                sentences[i + 1].length < threshold / 2) {
                processedText += sentences[i] + '.<br /><br />';
            } else {
                processedText += sentences[i] + '.';
            }
        }
        return processedText;
    };


    return (
        <>
            <Header />
            <section className='container-section-blog'>
                <h1>Detalhes do Post</h1>

                <div className='content-post'>

                    <div className='container-img-bl'>

                        <img src={gestor.url_imagem} alt="" />
                    </div>
                    <h2>{gestor.titulo}</h2>
                    <p className='cria'>Criado {moment(gestor.data_criacao).format('L')}</p>
                    <div className='text-complete'>
                        <p dangerouslySetInnerHTML={{ __html: processedText }}></p>
                    </div>




                </div>

                <div className='comentarios-blog'>


                    {
                        comentarios.slice(0, displayCount).map((value, index) => (
                            <div key={index}>
                                <div className='conteiner-info'>
                                    <div className='icon'>
                                        <FaUserAlt />
                                    </div>
                                    <p>
                                        {value.texto}
                                    </p>
                                </div>
                                <p className='cria-coment'>{moment(gestor.data_criacao).format('L')}</p>
                            </div>
                        ))
                    }

                    {
                        displayCount < comentarios.length && (
                            <button className='vermais-bt' onClick={() => setDisplayCount(comentarios.length)}>Ver mais</button>
                        )
                    }




                    <h2>Deixe seu comentário</h2>

                    <form onSubmit={handleSubmit}>
                        <div className='input-one'>
                            <TextField
                                id='outlined-basic'
                                label='Digite aqui...'
                                variant='outlined'
                                name='texto'
                                value={formData.texto}
                                onChange={handleChange}
                                multiline
                                rows={5}
                            />
                        </div>




                        <div className='button-option'>

                            {formData.texto === '' ?
                                <Button variant='contained' className='button' type='submit' disabled>
                                    Enviar
                                </Button>
                                :
                                <Button variant='contained' className='button' type='submit'>
                                    Enviar
                                </Button>

                            }

                        </div>
                    </form>



                </div>



            </section>
            <Footer />
        </>
    )
}
