import React from 'react';
import './styles.css';

import { useState, useEffect } from 'react';
import axios from 'axios';

import { AiFillYoutube } from 'react-icons/ai';
import { BiSolidRightArrow } from 'react-icons/bi';

function YouTubePlaylist() {
    const [videos, setVideos] = useState([]);
    const [numVideosToShow, setNumVideosToShow] = useState(4); // default para web
    const API_KEY = 'AIzaSyCYCGAo6Nv0kyIoEW-rwnTlbYrkiL9lv7k';
    const CHANNEL_ID = 'UCRkd1Zh-VxIL0wmrc0h5zcQ';

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get(`https://www.googleapis.com/youtube/v3/search?order=date&part=snippet&channelId=${CHANNEL_ID}&maxResults=4&key=${API_KEY}`);
                setVideos(response.data.items);
            } catch (error) {
                console.error("Error fetching videos:", error);
            }
        };

        fetchVideos();
    }, []);

    useEffect(() => {
        const updateNumVideosToShow = () => {
            // Se a largura da janela for menor que 768px, consideramos que é mobile
            if (window.innerWidth < 768) {
                setNumVideosToShow(2); // para mobile
            } else {
                setNumVideosToShow(4); // para web
            }
        };

        // Atualiza quando o componente é montado
        updateNumVideosToShow();

        // Adiciona um listener para o evento resize
        window.addEventListener('resize', updateNumVideosToShow);

        // Limpa o listener quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', updateNumVideosToShow);
        };
    }, []);

    return (
        <div>

            <div className='container-info-name'>
                <div className='org-name-icon'>
                    <AiFillYoutube size={40} />
                    <span>
                        <h2>Canadá Simplificado</h2>

                    </span>
                </div>

                <a href="https://www.youtube.com/@canadasimplificado" target="_blank" rel="noopener noreferrer">
                    <p>Ver Mais</p>
                    <BiSolidRightArrow />
                </a>

            </div>

            <div className="video-youtube-container">
                {videos.slice(0, numVideosToShow).map(video => (

                    <iframe
                        key={video.id.videoId}
                        className='content-iframe'
                        src={`https://www.youtube.com/embed/${video.id.videoId}`}
                        title={video.snippet.title}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>

                ))}
            </div>
            <div className='mobile-button-rs'>
            <a href="https://www.youtube.com/@canadasimplificado" target="_blank" rel="noopener noreferrer">
                    <p>Ver Mais</p>
                    <BiSolidRightArrow />
                </a>
            </div>
        </div>
    );
}

export default YouTubePlaylist;
